#featured-properties {
	padding: $contentPaddingX * 6 0;
	@media (max-width: $desktopTabletWidth) {
		padding-top: 0;
	}

	.section-title {
		text-align: center;
	}

	.featured-properties-slider-container {
		@media (max-width: $desktopTabletWidth) {
			padding-left: $contentPaddingX * 3;
			padding-right: $contentPaddingX * 3;
		}

		.slider-buttons {
			position: absolute;
			z-index: 9;
			left: 0;
			right: 0;
			top: 270px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media (min-width: $desktopTabletWidth) {
				display: none;
			}

			.button-next,
			.button-prev {
				background-repeat: no-repeat;
				background-position: center center;
				background-size: auto;
				width: 34px;
				height: 34px;
				display: block;
				cursor: pointer;
			}
			.button-next {
				background-image: url(../../images/arrow-active.png);
				margin-right: 1rem;
				&.swiper-button-disabled {
					background-image: url(../../images/arrow-inactive.png);
					transform: rotate(0.5turn);
				}
			}
			.button-prev {
				background-image: url(../../images/arrow-active.png);
				margin-left: 1rem;
				&:not(.swiper-button-disabled) {
					transform: rotate(0.5turn);
				}

				&.swiper-button-disabled {
					background-image: url(../../images/arrow-inactive.png);
				}
			}
		}

		.slider-pagination {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: $contentPaddingY;

			.swiper-pagination-bullet {
				width: 9px;
				height: 9px;
				border: 1px solid $accentColor;
				background-color: transparent;
				opacity: 1;

				&.swiper-pagination-bullet-active {
					background-color: $accentColor;
				}
			}
		}
	}

	.featured-properties-slider {
		margin-top: $contentPaddingY * 3;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		gap: $contentPaddingX * 1.5;
		position: relative;

		@media (max-width: $desktopTabletWidth) {
			flex-direction: column;
		}

		> div {
			.img {
				width: 100%;
				border-radius: 6px;
				overflow: hidden;
				img {
					width: 100%;
					height: 413px;
					object-fit: cover;
				}
			}
			.section-accent-title {
				text-align: left;
				color: $accentColor;
				margin-top: $contentPaddingY * 0.5;
			}
			.section-text {
				margin-top: $contentPaddingY * 0.5;
			}
		}
	}
}
