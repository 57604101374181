#explore {
	padding: $contentPaddingY * 5 0;

	.content {
		display: flex;
		gap: $contentPaddingX * 4;
		align-items: center;
		justify-content: space-between;

		@media (max-width: $desktopTabletWidth) {
			flex-direction: column-reverse;
		}
	}

	.image {
		width: 100%;
		height: 100%;
		max-width: 522px;
		max-height: 621px;

		img {
			@media (max-width: $desktopTabletWidth) {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.text {
		font-size: 18px;
		font-weight: 300;
		line-height: 34px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;
		@media (max-width: $desktopTabletWidth) {
			text-align: center;
			font-size: 16px;
			line-height: 30px;
		}

		margin-top: $contentPaddingY;
	}
	.button {
		margin-top: $contentPaddingY;
		@media (max-width: $desktopTabletWidth) {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.section-title {
		@media (max-width: $desktopTabletWidth) {
			text-align: center;
		}
	}

	.section-text {
		@media (max-width: $desktopTabletWidth) {
			text-align: center;
		}
		margin-top: $contentPaddingY;
	}
}
