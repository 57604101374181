body {
	margin: 0;
	padding: 0;
	background-color: $backgroundColor;
	font-family: $fontFamily;
	font-weight: 300;
	font-optical-sizing: auto;
	color: $color;
	font-size: 1rem;
	min-width: 360px;

	* {
		box-sizing: border-box;
	}

	p {
		margin: 0 0 1rem 0;
	}
	*:focus {
		outline: none;
	}
}

.content {
	@include content;
	position: relative;
}


section {
	position: relative;
	overflow: hidden;
	.content {
		z-index: 2;
	}
}

.section-bg-image {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

img {
	display: block;
}

.section-title {
	font-weight: 200;
	font-size: 40px;
	line-height: 56px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	@media (max-width: $desktopTabletWidth) {
		font-size: 24px;
		line-height: 32px;
	}
}

.section-text {
	font-size: 18px;
	font-weight: 300;
	line-height: 34px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	@media (max-width: $desktopTabletWidth) {
		font-size: 16px;
		line-height: 30px;
	}
}


.section-sub-title {
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;

	@media (max-width: $desktopTabletWidth) {
		font-size: 14px;
		line-height: 18px;
	}
}

.section-accent-title{
	font-size: 24px;
	font-weight: 200;
	line-height: 56px;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	text-align: left;

	@media (max-width: $desktopTabletWidth) {
		font-size: 20px;
		line-height: 34px;
	}
}