#contact-us {
	background-color: $footerColor;
	padding: $contentPaddingX * 6 0;

	@media (max-width: $desktopTabletWidth) {
		padding-bottom: 0;
	}

	.content {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: $contentPaddingX * 4;

		@media (max-width: $desktopTabletWidth) {
			flex-direction: column;
			justify-content: center;
		}

		.text-container {
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.section-title {
		@media (max-width: $desktopTabletWidth) {
			text-align: center;
		}
	}

	.section-text {
		@media (max-width: $desktopTabletWidth) {
			text-align: center;
		}
		margin-top: $contentPaddingY;
	}

	form {
		width: 100%;

		> div {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: $contentPaddingX * 2;
			margin-bottom: $contentPaddingY * 2;

			@media (max-width: $desktopTabletWidth) {
				flex-direction: column;
				justify-content: center;
			}
		}

		input {
			display: block;
			background-color: transparent;
			border: none;
			border-bottom: 1px solid #353535;
			padding: $contentPaddingY 0;
			width: calc(50% - $contentPaddingX);
			color: $color;
			@media (max-width: $desktopTabletWidth) {
				width: 100%;
			}

			&.invalid{
				border-color: red;
			}
		}

		input[name="message"]{
			width: 100%;
		}

		.button-container{
			margin-top: $contentPaddingY * 3;

			@media (max-width: $desktopTabletWidth) {
				align-items: center;
			}
		}

		@media (max-width: $desktopTabletWidth) {
			.input-gag{
				display: none;
			}
		}

	}
}
