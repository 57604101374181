#video {
	video {
		object-fit: fill;
		width: 100%;
	}

	.video-container {
		position: relative;
		cursor: pointer;

		&:before {
			content: "";
			position: absolute;
			z-index: 9;
			left: 50%;
			right: 0;
			top: 50%;
			bottom: 0;
			background-image: url("/assets/images/play-button.svg");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			width: 5rem;
			height: 5rem;
			transform: translateX(-50%) translateY(-50%);
		}

		&[data-play-status="play"]:before {
			background-image: url("/assets/images/pause-button.svg");
			bottom: 0;
			height: 3rem;
			left: 3rem;
			width: 3rem;
			top: auto;
		}
	}
}
