#home {
	min-height: 940px;

	.title {
		font-size: 54px;
		font-weight: 200;
		line-height: 67px;
		text-align: center;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;

		padding-top: 10rem;

		@media (max-width: $desktopTabletWidth) {
			font-size: 32px;
			line-height: 38px;
		}

	}
}
