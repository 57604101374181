#menu {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	right: 0;
	.content {
		> div {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: $contentPaddingY 0;
		}
	}

	.logo {
		width: 97px;
		height: 125px;
		@media (max-width: $desktopTabletWidth) {
			width: 49px;
			height: 63px;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	a {
		color: $color;
		text-decoration: none;
	}

	.menu-links {
		display: flex;
		align-items: center;
		gap: $contentPaddingX * 4;
		@media (max-width: $desktopBreakpointWidth) {
			gap: $contentPaddingX * 1;
		}
		@media (max-width: $desktopTabletWidth) {
			display: none;
			position: fixed;
			background-color: black;
			padding: 2rem;
			left: 0;
			top: 80px;
			right: 0;
			flex-direction: column;
		}

		a {
			font-weight: 200;
			&[data-menu-item="contact-us"]{
				display: none;
				@media (max-width: $desktopTabletWidth) {
					display: block;
				}
			}
		}
	}
	&.active {
		@media (max-width: $desktopTabletWidth) {
			background-color: black;
			.menu-links{
				display: flex;
			}
		}
	}

	.contact-buttons {
		display: flex;
		align-items: center;
		gap: $contentPaddingX * 2;
		@media (max-width: $desktopBreakpointWidth) {
			gap: $contentPaddingX * 1;
		}

		a[data-menu-item="contact-us"] {
			@media (max-width: $desktopTabletWidth) {
				display: none;
			}
		}
	}

	#menu-button {
		width: 24px;
		height: 24px;
		cursor: pointer;
		align-items: center;
		justify-content: center;

		display: none;
		@media (max-width: $desktopTabletWidth) {
			display: flex;
		}

		.burger {
			display: block;
		}
		.cross {
			display: none;
		}
		&.active {
			.burger {
				display: none;
			}
			.cross {
				display: block;
			}
		}
	}
}

body.scrolled {
	#menu {
		background-color: rgba(0, 0, 0, 0.5);
		&.active{
			background-color: black;
		}
	}
}
