.loading {
	position: relative;
	cursor: default;
	point-events: none;
	text-shadow: none !important;
	color: transparent !important;
	-webkit-transition: all 0s linear;
	transition: all 0s linear;

	&:before {
		position: absolute;
		content: "";
		top: 0%;
		left: 0%;
		background: rgba(18, 14, 0, 0.8);
		width: 100%;
		height: 100%;
		z-index: 10;
	}

	&:after {
		position: absolute;
		content: "";
		top: 50%;
		left: 50%;
		margin: -1.5em 0em 0em -1.5em;
		width: 3em;
		height: 3em;
		-webkit-animation: spin-preloader 0.6s linear;
		animation: spin-preloader 0.6s linear;
		-webkit-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
		border-radius: 500rem;
		border-color: #fff rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4) rgba(0, 0, 0, 0.4);
		border-style: solid;
		border-width: 0.1em;
		box-shadow: 0px 0px 0px 1px transparent;
		visibility: visible;
		z-index: 11;
	}
}

@-webkit-keyframes spin-preloader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spin-preloader {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
