#about-us {
	background-color: $accentColor;
	color: $backgroundColor;

	.content {
		display: flex;
		gap: $contentPaddingX * 4;
		align-items: center;
		justify-content: space-between;
		min-height: 748px;

		@media (max-width: $desktopTabletWidth) {
			justify-content: center;
			padding-bottom: 6rem;
		}

		> div {
			max-width: 500px;
		}
	}

	.image {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		@media (max-width: $desktopTabletWidth) {
			width: 100%;
		}

		&:before {
			content: "";
			background: rgb(194, 155, 87);
			background: linear-gradient(90deg, rgba(194, 155, 87, 1) 0%, rgba(194, 155, 87, 0) 100%);

			@media (max-width: $desktopTabletWidth) {
				background: linear-gradient(180deg, rgba(194, 155, 87, 1) 0%, rgba(194, 155, 87, 0) 100%);

			}
			width: 100%;
			height: 100%;
			position: absolute;
		}
		img {
			object-position: center;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.button {
		margin-top: $contentPaddingY;
		@media (max-width: $desktopTabletWidth) {
			margin-left: auto;
			margin-right: auto;
		}
	}
	.section-title {
		@media (max-width: $desktopTabletWidth) {
			text-align: center;
		}
	}
	.section-sub-title {
		margin-bottom: $contentPaddingY;
		@media (max-width: $desktopTabletWidth) {
			text-align: center;
		}
	}

	.section-text {
		margin-top: $contentPaddingY;
		@media (max-width: $desktopTabletWidth) {
			text-align: center;
		}
	}
}
