#footer {
	.top {
		background-color: $footerColor;
		padding: $contentPaddingY * 3 0;

		.content {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: $contentPaddingX * 4;

			@media (max-width: $desktopTabletWidth) {
				gap: $contentPaddingX * 2;
				flex-direction: column;
				align-items: normal;
			}
		}

		.title {
			font-size: 18px;
			font-weight: 500;
			line-height: 34px;
			text-align: left;
			text-underline-position: from-font;
			text-decoration-skip-ink: none;

			color: $accentLightColor;
			margin-bottom: $contentPaddingY;

			@media (max-width: $desktopTabletWidth) {
				font-size: 16px;
				line-height: 34px;
			}
		}
		.links {
			display: flex;
			flex-direction: column;
			gap: $contentPaddingY;
			@media (max-width: $desktopTabletWidth) {
				gap: $contentPaddingY * 0.5;
			}
			a {
				font-size: 18px;
				font-weight: 300;
				line-height: 34px;
				text-align: left;
				text-underline-position: from-font;
				text-decoration-skip-ink: none;

				color: $color;
				text-decoration: none;

				@media (max-width: $desktopTabletWidth) {
					font-size: 14px;
					line-height: 30px;
				}
			}
		}

		.links-container {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			gap: $contentPaddingX * 4;
			@media (max-width: $desktopTabletWidth) {
				gap: $contentPaddingY * 2;
			}

			.contact-us-follow-container {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				gap: $contentPaddingX * 4;

				@media (max-width: $desktopTabletWidth) {
					flex-direction: column;
					gap: $contentPaddingX;
				}
			}
		}

		.logo-container {
			max-width: 467px;
			@media (max-width: $desktopTabletWidth) {
				max-width: none;
				width: 100%;
			}
			.logo {
				margin-bottom: $contentPaddingY;
				@media (max-width: $desktopTabletWidth) {
					img {
						margin: 0 auto 0 auto;
					}
				}
			}

			.section-text {
				@media (max-width: $desktopTabletWidth) {
					text-align: center;
				}
			}
		}
		.quicklinks {
		}
		.contact-us {
		}
		.follow {
			.social-links {
				display: flex;
				gap: $contentPaddingY * 0.75;
				@media (max-width: $desktopTabletWidth) {
					gap: $contentPaddingY * 0.5;
				}
				a {
					color: $color;
					text-decoration: none;
					width: 19px;
					width: 19px;

					img {
						width: 100%;
						width: 100%;
						object-fit: contain;
					}
				}
			}
		}
	}

	.bottom {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: $contentPaddingX;
		background-color: $accentColor;
		color: $backgroundColor;
		font-weight: 300;
		font-size: 16px;
		line-height: 34px;
		text-align: left;
		text-underline-position: from-font;
		text-decoration-skip-ink: none;

		@media (max-width: $desktopTabletWidth) {
			gap: $contentPaddingX * 0.5;
			font-size: 10px;
			line-height: 12px;
		}

		.content {
			width: 100%;
			> div {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: $contentPaddingY 0;
			}
		}

		.links {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: $contentPaddingX * 2;
			@media (max-width: $desktopTabletWidth) {
				gap: $contentPaddingX * 0.5;
			}
			a {
				color: $backgroundColor;
				text-decoration: none;

				font-size: 16px;
				font-weight: 300;
				line-height: 34px;
				text-align: left;
				text-underline-position: from-font;
				text-decoration-skip-ink: none;

				@media (max-width: $desktopTabletWidth) {
					font-size: 10px;
					line-height: 12px;
				}
			}
		}
	}
}
