$fontFamily: "Inter", sans-serif;

$backgroundColor: #000;
$color: #fff;
$accentColor: #c29b57;
$accentLightColor: #FFDEA4;
$footerColor: #120E00;

$contentMaxWidth: 1200px;

$mobileWidth: 520px;
$tabletWidth: 768px;
$desktopTabletWidth: 992px;

$desktopBreakpointWidth: 1100px;

$desktopWidth: $contentMaxWidth;

$contentPaddingY: 1rem;
$contentPaddingX: 1rem;
