@mixin no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin content {
	margin-left: auto;
	margin-right: auto;
	max-width: calc(#{$contentMaxWidth} + #{$contentPaddingX * 2} + #{$contentPaddingX * 2});
	padding-left: $contentPaddingX * 2;
	padding-right: $contentPaddingX * 2;

	@media (max-width: $desktopTabletWidth) {
		max-width: calc(#{$contentMaxWidth} + #{$contentPaddingX} + #{$contentPaddingX});
		padding-left: $contentPaddingX;
		padding-right: $contentPaddingX;
	}
}

@mixin animationTransition($time: 0.5s) {
	-webkit-transition: all $time ease-in-out;
	-moz-transition: all $time ease-in-out;
	-o-transition: all $time ease-in-out;
	transition: all $time ease-in-out;
	-ms-transition: all $time ease-in-out;
}
