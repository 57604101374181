#why {
	padding: $contentPaddingY * 5 0;

	.content {
		display: flex;
		gap: $contentPaddingX * 5;
		align-items: flex-start;
		justify-content: space-between;

		@media (max-width: $desktopTabletWidth) {
			flex-direction: column;
			align-items: center;
		}
	}

	.image {

		width: 522px;
		height: 621px;
		@media (max-width: $desktopTabletWidth) {
			width: 100%;
			height: 100%;
			max-width: 522px;
		}
		img {
			border-radius: 6px;
			@media (max-width: $desktopTabletWidth) {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}

	.section-title {
		margin-bottom: $contentPaddingY;
		@media (max-width: $desktopTabletWidth) {
			text-align: center;
		}
	}

	.reasons {
		> div {
			border-bottom: 1px solid #353535;
			padding: $contentPaddingY * 2 0 $contentPaddingY * 3 0;
		}

		.first{
			padding-top: 0;
		}
		.last{
			padding-bottom: 0;
			border-bottom: none;
		}

		.img {
			width: 50px;
			height: 50px;
			background-color: $accentColor;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50px;

			@media (max-width: $desktopTabletWidth) {
				width: 43px;
				height: 43px;
			}

			img {
				width: 24px;
				height: 24px;
			}
		}
		.section-text {
			margin-top: $contentPaddingY * 0.5;
		}

		.section-accent-title {
			color: $accentColor;
			margin-top: $contentPaddingY * 0.5;
		}
	}
}
