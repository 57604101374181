.button {
	font-weight: 500;
	text-align: center;
	padding: $contentPaddingY * 1.1 $contentPaddingX * 1.5;
	border-radius: 3px;
	text-decoration: none;
	cursor: pointer;
	display: table;

	background-color: $backgroundColor;
	color: $color !important;

	border: none;

	@media (max-width: $desktopTabletWidth) {
		padding: $contentPaddingY * 0.75 $contentPaddingX * 0.75;
	}

	&.wide {
		display: block;
	}

	&.white {
		background-color: $color;
		color: $backgroundColor !important;
	}
	&.accent {
		background-color: $accentColor;
		color: $color !important;
	}
}
